const getFileMenu = [
  {
    icon: 'mdi-image',
    title: 'Images',
    to: { path: '/media', query: { type: 'image' } },
  },
  {
    icon: 'mdi-video',
    title: 'Video',
    to: { path: '/media', query: { type: 'video' } },
  },
  {
    icon: 'mdi-volume-medium',
    title: 'Audio',
    to: { path: '/media', query: { type: 'audio' } },
  },
  {
    icon: 'mdi-file',
    title: 'Document',
    to: { path: '/media', query: { type: 'doc' } },
  },
];
const Items = [];

const getFile = limit => {
  return limit ? Items.slice(0, limit) : Items;
};

export { getFileMenu, getFile };
